<template>
  <LayoutLogin>
    <div class="w-full max-w-2xl flex flex-col items-center gap-8 my-12">
      <ULink to="/">
        <img :src="logoUrl" alt="Logo" class="w-full max-w-[300px]" />
      </ULink>

      <div
        class="w-full flex flex-col sm:flex-row items-center gap-4 px-4 sm:px-0"
      >
        <div class="w-full flex flex-col items-center gap-2">
          <p class="text-green-600">Member login</p>
          <UForm class="w-full space-y-4" :state="state" @submit="handleLogin">
            <UFormGroup label="Username" size="xl">
              <UInput
                v-model="state.username"
                placeholder="Username"
                autofocus
              />
            </UFormGroup>
            <UFormGroup label="Password" size="xl">
              <UInput
                v-model="state.password"
                type="password"
                placeholder="Password"
              />
            </UFormGroup>
            <UButton
              type="submit"
              color="white"
              size="xl"
              class="w-full text-white font-bold uppercase tracking-wide"
            >
              Member Login
            </UButton>
          </UForm>
        </div>

        <div class="w-full flex flex-col items-center gap-4">
          <p class="text-red-600">Not a member?</p>
          <strong class="text-xl">Join Now to Get</strong>
          <ul>
            <li>
              <font-awesome-icon
                icon="fa-solid fa-circle-check"
                class="text-green-600 mr-1"
              />
              The
              <strong>Highest Quality</strong>
              Adult Videos
            </li>
            <li>
              <font-awesome-icon
                icon="fa-solid fa-circle-check"
                class="text-green-600 mr-1"
              />
              Every Model is
              <strong>Hand-Picked</strong>
              Hotness
            </li>
            <li>
              <font-awesome-icon
                icon="fa-solid fa-circle-check"
                class="text-green-600 mr-1"
              />
              New Videos Added
              <strong>2-3+</strong>
              Daily
            </li>
            <li>
              <font-awesome-icon
                icon="fa-solid fa-circle-check"
                class="text-green-600 mr-1"
              />
              <strong>&nbsp;Unlimited</strong>
              Download & Streaming
            </li>
          </ul>
          <UButton
            color="green"
            class="text-white text-xl tracking-wide uppercase font-bold whitespace-nowrap"
            style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5)"
            size="xl"
          >
            Get your password now
          </UButton>
        </div>
      </div>

      <p class="w-full text-center mt-4 px-8">
        Please note that if you have just purchased or upgraded a membership it
        may take up to 15 minutes for your login to become functional. If the
        issue persists longer than 15 minutes, please contact customer support.
        Multiple login attempts in a short time span may result in your account
        getting blocked.
      </p>

      <AdsFooter />
    </div>

    <Footer />
  </LayoutLogin>
</template>

<script setup>
  const router = useRouter()
  const toast = useToast()
  const userStore = useUserStore()
  const currentSite = inject("currentSite")
  const logoUrl = `/images/sites/${currentSite.value.slug}/logo.png`

  useSiteSeo({
    defaulTitle: "Member Login | {{ siteName }}",
    defaulDescription: "Login to your member account",
  })

  const state = reactive({
    username: undefined,
    password: undefined,
  })

  const handleLogin = async (event) => {
    await userStore.login(event.data.username, event.data.password)

    if (userStore.loggedIn) {
      if (userStore.redirectUrl) {
        navigateTo(userStore.redirectUrl, { external: true })
      } else {
        router.push("/members/upgrade")
      }
    } else {
      toast.add({
        title: "Login failed",
        description: "Please check your credentials",
      })
    }
  }
</script>

<style scoped>
  ul {
    @apply list-none;

    li {
      @apply text-xl;
    }
  }
</style>
